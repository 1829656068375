/* eslint-disable */
/**
 * Put any initialization code for 3rd-party sdks here.
 * This file is NOT included when tests are run.
 */

// Google Analytics
(function(i,s,o,g,r,a,m){
  // Customization of the default snippet to account for the mobile app:
  // If the ga object has already been created, don't load analytics.js.
  if(i[r])return;
  i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','//www.google-analytics.com/analytics.js','ga');

// GTM
// #if process.env.ENV_NAME === 'production'
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
{'gtm.start': new Date().getTime(),event:'gtm.js'}
);var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-W774BP9');
// #endif

// #if process.env.ENV_NAME !== 'production' || process.env.ENV_NAME === 'launch-control'
// (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
// {'gtm.start': new Date().getTime(),event:'gtm.js'}
// );var f=d.getElementsByTagName(s)[0],
// j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
// 'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=KLj7wL3jGSILvMG5qUdpAA&gtm_preview=env-55&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
// })(window,document,'script','dataLayer','GTM-W774BP9');
// #endif
